import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ConfirmHealthCarePatientAttributesDialog from '@/components/Dialogs/ConfirmHealthCarePatientAttributesDialog';
import ConnectiveJsonDialog from '@/components/Dialogs/ConnectiveJsonDialog';
import PdfDialog from '@/components/Dialogs/PdfDialog';
import { H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { GetAccountDiagnosisResponseType } from '@/graphql/remotePatientMonitoring';
import { GET_ACCOUNT_DIAGNOSES } from '@/graphql/remotePatientMonitoring';
import {
  AccountCareSummaryStatus,
  CONNECTIVE_JSON_PREVIEW,
  GET_ACCOUNT_CARE_SUMMARIES,
  GET_DOCUMENT_PREVIEW,
  GET_RPM_HEALTH_TRACKINGS,
  IMPORT_ACCOUNT_CARE_SUMMARY,
  type AccountCareSummaryRequest,
  type GetAccountCareSummariesResponse,
  type GetConnectiveJsonPreviewResponse,
  type GetDocumentPreviewResponse,
  type GetRpmHealthTrackingsResponse,
  type ImportAccountCareSummaryResponse
} from '@/graphql/rpmWorkflow';
import useToken from '@/hooks/useToken';
import type { FetchDocumentPreviewProps } from '@/routes/PatientDetails/RemotePatientMonitoring/ClinicalSummary';
import AccountsDiagnoses from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ClinicalSummary/AccountsDiagnoses';
import PatientA1CReadings from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ClinicalSummary/PatientA1CReadings';
import { Role } from '@/types/admin';
import { DiagnosisSystems, RpmHealthTrackingKeys } from '@/types/remotePatientMonitoring';
import { tomorrow } from '@/util/date';
import { formatDate, formatDateAndTime } from '@/util/format';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CachedIcon from '@mui/icons-material/Cached';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Chip, LinearProgress, Stack } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  selectionModel: GridRowId[];
  setSelectionModel: (selectionModel: GridRowId[]) => void;
  isReadOnly: boolean;
  rpmFollowupConsultId?: string;
};

const ClinicalSummariesDataGrid: React.FC<Props> = ({ selectionModel, setSelectionModel, isReadOnly, rpmFollowupConsultId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { roleType } = useToken();
  const { id: truentityId } = useParams();
  const isSuperAdmin: boolean = useMemo(() => roleType === Role.SUPER, [roleType]);

  const { patientInfo } = useContext(PatientDetailContext);
  const [currentA1CPage, setCurrentA1CPage] = useState(1);
  const { setHasAvailableAccountCareSummaries, shouldReFetchAccountCareSummaries, setShouldReFetchAccountCareSummaries } =
    useRpmSetupStore();

  const [getConnectiveJsonPreview] = useLazyQuery<GetConnectiveJsonPreviewResponse>(CONNECTIVE_JSON_PREVIEW);
  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    data: accountCareSummariesData,
    error: getAccountCareSummariesError,
    refetch: refetchAccountCareSummaries,
    loading: loadingAccountCareSummaries
  } = useQuery<GetAccountCareSummariesResponse>(GET_ACCOUNT_CARE_SUMMARIES, {
    variables: {
      truentityId,
      filterOptions: {
        rpmFollowupConsultId: rpmFollowupConsultId
      },
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network',
    skip: !truentityId
  });
  const [getAccountDiagnoses, { data: accountDiagnosis, loading: accountDiagnosisLoading }] = useLazyQuery<GetAccountDiagnosisResponseType>(
    GET_ACCOUNT_DIAGNOSES,
    {
      variables: { truentityId: truentityId, filterOptions: { isMonitorable: true, prioritySystem: DiagnosisSystems.ICD } },
      fetchPolicy: 'cache-and-network'
    }
  );
  const [
    getAccountHealthTracking,
    { data: rpmHealthTrackingsData, loading: rpmHealthTrackingsLoading, error: rpmHealthTrackingsError, refetch: refetchHealthTracking }
  ] = useLazyQuery<GetRpmHealthTrackingsResponse>(GET_RPM_HEALTH_TRACKINGS, {
    variables: {
      truentityId: truentityId,
      pageNum: currentA1CPage,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        healthDataType: RpmHealthTrackingKeys.HEMOGLOBIN_A1C
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [
    importAccountCareSummary,
    { loading: importAccountCareSummaryLoading, error: importAccountCareSummaryError, data: importAccountCareSummaryResponse }
  ] = useMutation<ImportAccountCareSummaryResponse>(IMPORT_ACCOUNT_CARE_SUMMARY);

  const fetchDocumentPreview = useCallback(
    async ({ s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: s3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          const modal = showModal(PdfDialog, {
            title: 'Clinical Document Preview',
            pdfUrl: documentResponse?.data?.documentPreview?.documentUrl,
            hideDialog: () => {
              modal.hide();
            }
          });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getDocumentPreview, showModal]
  );

  const fetchAccountHealth = async () => {
    try {
      await getAccountDiagnoses();
      await getAccountHealthTracking();
    } catch (err) {
      enqueueSnackbar("Failed to fetch Patient's past medical history", { variant: 'error' });
    }
  };

  const getAccountCareSummaryChip = useCallback((value: AccountCareSummaryStatus) => {
    if (!value) {
      return <span>-</span>;
    }
    let color: 'success' | 'error' | 'warning' = 'success';
    if (value === AccountCareSummaryStatus.Requested) {
      color = 'warning';
    } else if (value === AccountCareSummaryStatus.Unavailable) {
      color = 'error';
    }

    return <Chip variant="filled" color={color} label={value} />;
  }, []);

  const fetchConnectiveJsonPreview = useCallback(
    async ({ s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getConnectiveJsonPreview({
          variables: {
            jsonS3Key: s3Key
          }
        });
        if (documentResponse?.data?.connectiveJsonPreview?.documentUrl) {
          const jsonUrl = documentResponse?.data?.connectiveJsonPreview?.documentUrl;
          const modal = showModal(ConnectiveJsonDialog, {
            title: 'Connective health document Preview',
            jsonUrl: jsonUrl,
            hideDialog: () => {
              modal.hide();
            }
          });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getConnectiveJsonPreview, showModal]
  );

  const handleConfirmPatientImport = () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Submit Request',
      message: `Are you sure you want to submit a request to import ${
        patientInfo?.user?.firstName ? patientInfo?.user?.firstName + "'s" : ''
      } clinical documents?`,
      onAgree: () => {
        importAccountCareSummary({
          variables: {
            truentityId,
            appointmentDate: formatDate(tomorrow(), 'YYYY-MM-DD'),
            rpmFollowupConsultId: rpmFollowupConsultId
          }
        }).catch(err => console.error(err));
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  const handlePatientImport = () => {
    const modal = showModal(ConfirmHealthCarePatientAttributesDialog, {
      title: 'Please confirm the Patient Details',
      onPatientUpdated: () => {
        handleConfirmPatientImport();
        modal.hide();
      },
      skipDialog: () => {
        handleConfirmPatientImport();
        modal.hide();
      },
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const columns: GridColDef<AccountCareSummaryRequest>[] = useMemo(
    () => [
      {
        field: 'requestedOn',
        headerName: 'Requested On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDateAndTime(params.value)
      },
      {
        field: 'importedOn',
        headerName: 'Imported On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDateAndTime(params.value)
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return getAccountCareSummaryChip(params.value);
        }
      },
      {
        field: 's3Keys',
        headerName: 'Documents',
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          if (params.row.status === AccountCareSummaryStatus.Available) {
            return (
              <>
                <Button
                  size="small"
                  variant={'text'}
                  startIcon={<FileOpenIcon />}
                  disableElevation
                  onClick={() => fetchDocumentPreview({ s3Key: params.row.s3Key })}
                />
                {params.row.jsonS3Key !== '' && params.row.jsonS3Key !== null && isSuperAdmin && (
                  <Button
                    size="small"
                    variant={'text'}
                    startIcon={<DataObjectIcon />}
                    disableElevation
                    onClick={() => fetchConnectiveJsonPreview({ s3Key: params.row.jsonS3Key })}
                  />
                )}
              </>
            );
          } else {
            return <span>-</span>;
          }
        }
      },
      {
        field: 'submittedForReviewOn',
        headerName: 'Last Submitted For Review On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDate(params.value)
      }
    ],
    [getAccountCareSummaryChip, isSuperAdmin, fetchDocumentPreview, fetchConnectiveJsonPreview]
  );

  useEffect(() => {
    if (importAccountCareSummaryError) {
      enqueueSnackbar('Failed to import patient care summary ', { variant: 'error' });
    } else if (importAccountCareSummaryResponse?.importAccountCareSummary?.status === 'Success') {
      enqueueSnackbar('Successfully requested patient care summary import ', { variant: 'success' });
      setShouldReFetchAccountCareSummaries(true);
    }
  }, [importAccountCareSummaryError, importAccountCareSummaryResponse, enqueueSnackbar, refetchAccountCareSummaries]);

  useEffect(() => {
    refetchHealthTracking();
  }, [currentA1CPage]);

  useEffect(() => {
    if (rpmHealthTrackingsError) {
      enqueueSnackbar('Failed to get Patient HbA1c data', { variant: 'error' });
    }
  }, [rpmHealthTrackingsError]);

  useEffect(() => {
    if (getAccountCareSummariesError) {
      console.error(getAccountCareSummariesError);
      enqueueSnackbar('Failed to get patient care summaries ', { variant: 'error' });
    }
  }, [enqueueSnackbar, getAccountCareSummariesError]);

  useEffect(() => {
    if (accountCareSummariesData?.accountCareSummaries?.accountCareSummaries) {
      fetchAccountHealth();
      const isOneClinicalDocPresent =
        accountCareSummariesData?.accountCareSummaries?.accountCareSummaries.some(
          summary => summary.status === AccountCareSummaryStatus.Available
        ) ?? false;
      setHasAvailableAccountCareSummaries(isOneClinicalDocPresent);
    }
  }, [accountCareSummariesData]);

  useEffect(() => {
    if (shouldReFetchAccountCareSummaries) {
      refetchAccountCareSummaries().catch(err => console.error(err));
    }
  }, [refetchAccountCareSummaries, shouldReFetchAccountCareSummaries]);

  return (
    <Stack direction="column" justifyContent={'flex-start'} alignItems={'stretch'}>
      <Stack direction={'row'} spacing={2} margin={1} justifyContent={'space-between'} alignItems={'center'}>
        <H4>Clinical Summary Requests</H4>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Button
            disabled={
              loadingAccountCareSummaries ||
              importAccountCareSummaryLoading ||
              (patientInfo?.rpmStatus === 'ENROLLED' && !rpmFollowupConsultId) ||
              patientInfo?.rpmStatus === 'UNENROLLED'
            }
            label={'Refresh'}
            variant={'outlined'}
            startIcon={<CachedIcon />}
            onClick={() => {
              setShouldReFetchAccountCareSummaries(true);
            }}
          />
          <Button
            label={'Import'}
            disabled={
              importAccountCareSummaryLoading ||
              (patientInfo?.rpmStatus === 'ENROLLED' && !rpmFollowupConsultId) ||
              patientInfo?.rpmStatus === 'UNENROLLED'
            }
            onClick={handlePatientImport}
          />
        </Stack>
      </Stack>
      <TruentityDataGrid
        name={'dg-account-care-summaries'}
        pagination={false}
        disabled={isReadOnly}
        slots={{
          loadingOverlay: LinearProgress
        }}
        loading={loadingAccountCareSummaries || importAccountCareSummaryLoading}
        checkboxSelection
        disableRowSelectionOnClick={true}
        disableMultipleRowSelection={true}
        rowSelectionModel={selectionModel}
        hideFooterSelectedRowCount
        onRowSelectionModelChange={newSelection => {
          setSelectionModel(newSelection);
        }}
        isRowSelectable={params => params.row.status === AccountCareSummaryStatus.Available}
        autoHeight
        columns={columns}
        rows={accountCareSummariesData?.accountCareSummaries?.accountCareSummaries ?? []}
      />
      <AccountsDiagnoses accountDiagnosis={accountDiagnosis} accountDiagnosisLoading={accountDiagnosisLoading} />
      <PatientA1CReadings
        healthTrackings={rpmHealthTrackingsData?.getAccountHealthTrackings?.healthTrackings ?? []}
        healthTrackingsLoading={rpmHealthTrackingsLoading}
        currentA1CPage={currentA1CPage}
        setCurrentA1CPage={setCurrentA1CPage}
        totalHealthTrackingsCount={rpmHealthTrackingsData?.getAccountHealthTrackings?.meta?.totalCount ?? 0}
      />
    </Stack>
  );
};

export default ClinicalSummariesDataGrid;
