import { UPDATE_RPM_REVIEWER } from '@/graphql/provider';
import type { RelyingPartyProviderType } from '@/graphql/remotePatientMonitoring';
import { useMutation } from '@apollo/client';
import { Box, Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  data: RelyingPartyProviderType;
  hideDialog: () => void;
};

type FormValues = {
  isRpmReviewer: boolean;
  firstName: string;
  lastName: string;
  npiNumber: string;
  email: string | undefined;
};

const defaultValues: FormValues = {
  isRpmReviewer: false,
  firstName: '',
  lastName: '',
  npiNumber: '',
  email: ''
};

const UpdateRelyingPartyProviderDialog = ({ data, title, hideDialog, ...props }: Props): React.ReactElement => {
  const [updateRpmReviewer] = useMutation(UPDATE_RPM_REVIEWER);

  const { control, reset, handleSubmit } = useForm<FormValues>({ defaultValues });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      updateRpmReviewer({
        variables: {
          providerId: data.provider.id,
          isRpmReviewer: values.isRpmReviewer
        }
      })
        .then(response => {
          hideDialog();

          const data = response.data!.updateRpmReviewer;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
        })
        .catch(error => {
          console.error(error);
          enqueueSnackbar('Unable to update provider', {
            variant: 'error'
          });
          hideDialog();
        });
    } catch (err) {
      const failMessage = 'Failed to update a provider';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (data) {
      const providerData: FormValues = {
        isRpmReviewer: data.isRpmReviewer,
        firstName: data.provider.individualFirstName,
        lastName: data.provider.individualLastName,
        npiNumber: data.provider.npiNumber,
        email: data.provider.email
      };

      reset(providerData);
    }
  }, [data, reset]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Controller
              control={control}
              name="isRpmReviewer"
              render={({ field: { onChange, value } }) => (
                <Grid container alignItems="center" justifyContent="flex-end">
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="RPM Reviewer" />
                </Grid>
              )}
            />
          </Box>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField disabled autoFocus onChange={onChange} value={value} label={'First Name'} />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField disabled autoFocus onChange={onChange} value={value} label={'Last Name'} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="npiNumber"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField disabled autoFocus onChange={onChange} value={value} label={'NPI Number'} />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField disabled autoFocus onChange={onChange} value={value} label={'Email'} />
                )}
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default UpdateRelyingPartyProviderDialog;
