import ControlledAutoComplete from '@/components/AutoComplete/ControlledAutoComplete';
import Button from '@/components/Button';
import PdfDialog from '@/components/Dialogs/PdfDialog';
import { H3, H5 } from '@/components/Typography';
import type {
  CarePlanByAccount,
  GetCarePlanByAccountResponse,
  GetCarePlanValuesResponse,
  UpdateRpmSetupResponse
} from '@/graphql/remotePatientMonitoring';
import { ADD_CARE_PLAN, GET_CARE_PLAN_BY_ACCOUNT, GET_CARE_PLAN_VALUES, UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import type { GetDocumentPreviewResponse, GetRpmAccountSummaryResponse } from '@/graphql/rpmWorkflow';
import { GET_DOCUMENT_PREVIEW, GET_RPM_ACCOUNT_SUMMARY, REGENERATE_ACCOUNT_CARE_PLAN_REPORT } from '@/graphql/rpmWorkflow';
import useAutoEnrollPatient from '@/hooks/useAutoEnrollPatient';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { RpmSetupStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { updateRpmTabStatus } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Grid, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BaseRpmWorkflowTabContent from './BaseRpmWorkflowTabContent';

export type FormValues = {
  programDescription: string;
  conditions: string[];
  outcomes: string[];
  goals: string[];
  barriers: string[];
};

export const defaultValues: FormValues = {
  programDescription: '',
  conditions: [],
  outcomes: [],
  goals: [],
  barriers: []
};

interface FetchDocumentPreviewProps {
  s3Key: string;
  type: 'CarePlan' | 'AccountCareSummary';
}

const CarePlan = () => {
  const { showModal } = useModal();
  const { isReadOnly } = useRpmWorkflowStore();
  const { enqueueSnackbar } = useSnackbar();
  const { rpmSetupTabs, editRpmStatus } = useRpmSetupStore();
  const { triggerAutoEnrollment } = useAutoEnrollPatient({
    notConsiderForRpmEnrollments: [RpmWorkflowTab.CARE_PLAN]
  });

  const { id: truentityId } = useParams();

  const [carePlanByAccount, setCarePlanByAccount] = useState<CarePlanByAccount>();

  const [getCarePlanByAccount, { data: carePlanByAccountData }] = useLazyQuery<GetCarePlanByAccountResponse>(GET_CARE_PLAN_BY_ACCOUNT, {
    fetchPolicy: 'cache-and-network'
  });

  const [getCarePlanValues, { data: carePlanValuesData }] = useLazyQuery<GetCarePlanValuesResponse>(GET_CARE_PLAN_VALUES, {
    fetchPolicy: 'cache-and-network'
  });

  const {
    data: rpmAccountSummaryData,
    error: getRpmAccountSummaryError,
    loading: loadingRpmAccountSummary,
    refetch: reFetchRpmAccountSummary
  } = useQuery<GetRpmAccountSummaryResponse>(GET_RPM_ACCOUNT_SUMMARY, {
    variables: {
      truentityId
    },
    skip: !truentityId,
    fetchPolicy: 'cache-and-network'
  });

  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW);

  const [addCarePlan] = useMutation(ADD_CARE_PLAN);

  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const [regenerateCarePlanReport, { loading: loadingRegenerateCarePlanReport }] = useMutation(REGENERATE_ACCOUNT_CARE_PLAN_REPORT, {
    variables: {
      truentityId
    },
    onCompleted: () => {
      reFetchRpmAccountSummary();
    }
  });

  const { handleSubmit, reset, control, watch } = useForm<FormValues>({ defaultValues });
  const conditions = watch('conditions');
  const outcomes = watch('outcomes');
  const goals = watch('goals');
  const barriers = watch('barriers');

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const inputFieldValues = {
        truentityId: truentityId,
        conditionPlans: values.conditions,
        outcomePlans: values.outcomes,
        goalPlans: values.goals,
        barrierPlans: values.barriers
      };

      await addCarePlan({
        variables: inputFieldValues
      });

      if (values.conditions?.length > 0 && values.outcomes?.length > 0 && values.goals?.length > 0 && values.barriers?.length > 0) {
        updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CARE_PLAN, editRpmStatus, updateRpmSetup, false).then(res => {
          if (res) triggerAutoEnrollment();
        });
      } else {
        updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CARE_PLAN, editRpmStatus, updateRpmSetup, false, RpmSetupStatusTypes.IN_PROGRESS);
      }
      reFetchRpmAccountSummary();
      enqueueSnackbar('Successfully saved the Care Plan', { variant: 'success' });
    } catch (error) {
      console.error('Error adding care plan:', error);
      enqueueSnackbar('Error adding care plan', { variant: 'error' });
    }
  };

  const populateFormWithCarePlanByAccountData = useCallback(() => {
    if (carePlanByAccount) {
      const patientCarePlan: FormValues = {
        programDescription: carePlanByAccount?.programDescription,
        conditions: carePlanByAccount?.conditions,
        outcomes: carePlanByAccount?.outcomes,
        goals: carePlanByAccount?.goals,
        barriers: carePlanByAccount?.barriers
      };
      reset(patientCarePlan);
    }
  }, [carePlanByAccount, reset]);

  const fetchDocumentPreview = useCallback(
    async ({ s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: s3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          fetch(`${documentResponse?.data?.documentPreview?.documentUrl}`)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
              const modal = showModal(PdfDialog, {
                title: 'Care Plan Report Preview',
                pdfUrl: url,
                hideDialog: () => {
                  modal.hide();
                }
              });
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getDocumentPreview, showModal]
  );

  const hasRpmCarePlanDocument = useMemo(
    () => (rpmAccountSummaryData?.getRpmAccountSummary?.carePlanS3Key?.length ?? 0) > 0,
    [rpmAccountSummaryData]
  );

  const isAnyFiledValuePresent = useMemo(
    () => conditions.length > 0 || outcomes.length > 0 || goals.length > 0 || barriers.length > 0,
    [conditions, outcomes, goals, barriers]
  );

  useEffect(() => {
    if (carePlanByAccountData?.getCarePlanByAccount) {
      const carePlan = carePlanByAccountData?.getCarePlanByAccount;
      setCarePlanByAccount(carePlan);
    }
  }, [carePlanByAccountData?.getCarePlanByAccount]);

  useEffect(() => {
    if (getRpmAccountSummaryError) {
      enqueueSnackbar('Failed to retrieve Care Plan document ', { variant: 'error' });
    }
  }, [enqueueSnackbar, getRpmAccountSummaryError]);

  useEffect(() => {
    getCarePlanByAccount({
      variables: {
        truentityId: truentityId
      }
    }).catch(err => console.error(err));
  }, [carePlanByAccountData, getCarePlanByAccount, truentityId]);

  useEffect(() => {
    getCarePlanValues().catch(err => console.error(err));
  }, [carePlanByAccountData, getCarePlanValues]);

  useEffect(() => {
    populateFormWithCarePlanByAccountData();
  }, [carePlanByAccountData, populateFormWithCarePlanByAccountData]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <Stack spacing={3}>
          <H3>Care Plan</H3>

          <Stack direction={'column'} justifyContent={'space-evenly'}>
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%',
                height: 'auto',
                my: 2,
                p: 1,
                border: '1px solid',
                borderColor: theme => theme.palette.divider,
                backgroundColor: color.grey50,
                borderRadius: 1
              }}
            >
              <Box flex={'1'} display={'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center">
                {hasRpmCarePlanDocument ? (
                  <>
                    <Box
                      sx={{ width: '66px' }}
                      component={'img'}
                      src={'https://truentity-general.s3.amazonaws.com/Indycare-CarePlan-Thumbnail.png'}
                    />
                    <H5
                      sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: color.black100,
                        marginLeft: 2
                      }}
                    >
                      Preview Care Plan Document
                    </H5>
                  </>
                ) : (
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: color.black100,
                      marginLeft: 2
                    }}
                  >
                    Care Plan Document
                  </H5>
                )}
              </Box>
              <Stack flexDirection="row" justifyContent="flex-end" columnGap={2}>
                <Button
                  variant="outlined"
                  label={hasRpmCarePlanDocument ? 'Regenerate' : 'Generate'}
                  disabled={loadingRpmAccountSummary || !isAnyFiledValuePresent}
                  isLoading={loadingRegenerateCarePlanReport}
                  onClick={() => regenerateCarePlanReport()}
                />
                {hasRpmCarePlanDocument && (
                  <Button
                    sx={{ width: '86px' }}
                    label="View"
                    disabled={loadingRegenerateCarePlanReport || loadingRpmAccountSummary}
                    onClick={() => {
                      fetchDocumentPreview({
                        s3Key: rpmAccountSummaryData?.getRpmAccountSummary?.carePlanS3Key,
                        type: 'CarePlan'
                      });
                    }}
                  />
                )}
              </Stack>
            </Stack>

            <Box p={2} mt={1} sx={{ border: '1px solid', borderColor: theme => theme.palette.divider, borderRadius: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <H5 sx={{ padding: theme.spacing(2) }}>Conditions</H5>
                  <ControlledAutoComplete
                    control={control}
                    name={'conditions'}
                    options={carePlanValuesData?.getCarePlanValues?.conditions || []}
                    label={'Conditions'}
                    onUpdate={handleSubmit(onSubmit)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <H5 sx={{ padding: theme.spacing(2) }}>Outcomes</H5>
                  <ControlledAutoComplete
                    control={control}
                    name={'outcomes'}
                    options={carePlanValuesData?.getCarePlanValues?.outcomes || []}
                    label={'Outcomes'}
                    onUpdate={handleSubmit(onSubmit)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <H5 sx={{ padding: theme.spacing(2) }}>Goals</H5>
                  <ControlledAutoComplete
                    control={control}
                    name={'goals'}
                    options={carePlanValuesData?.getCarePlanValues?.goals || []}
                    label={'Goals'}
                    onUpdate={handleSubmit(onSubmit)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <H5 sx={{ padding: theme.spacing(2) }}>Barriers</H5>
                  <ControlledAutoComplete
                    control={control}
                    name={'barriers'}
                    options={carePlanValuesData?.getCarePlanValues?.barriers || []}
                    label={'Barriers'}
                    onUpdate={handleSubmit(onSubmit)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default CarePlan;
