import AutoSave from '@/components/AutoSave';
import Button from '@/components/Button';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import PinDialog from '@/components/Dialogs/PinDialog';
import QRCodeDialog from '@/components/Dialogs/QRCodeDialog';
import type { JSONData } from '@/components/JsonKit/JsonEditor';
import { Body2 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import { SET_ACCOUNT_TEMP_VITAL_ACCESS_PIN, UPDATE_ACCOUNT_SETTING } from '@/graphql/account';
import { theme } from '@/styles/mui-theme';
import { getAccountTempAccessUrl } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { extractValueFromJson } from '@/util/rpm';
import { useMutation, useReactiveVar } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PatternIcon from '@mui/icons-material/Pattern';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Checkbox, FormControlLabel, Stack, Tooltip } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { ChangeEvent } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type FormValues = {
  temporaryVitalReadingAccess: boolean;
};

const defaultValues: FormValues = {
  temporaryVitalReadingAccess: false
};

const PatientSettings = () => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();

  const setPinRef = useRef<HTMLButtonElement | null>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isPatientTempVitalAccessBlocked, setIsPatientTempVitalAccessBlocked] = useState<boolean>(false);
  const jsonPatientSettings = useRef<JSONData>({});

  const [updateAccountList] = useMutation(UPDATE_ACCOUNT_SETTING);
  const [setPinForAccount] = useMutation(SET_ACCOUNT_TEMP_VITAL_ACCESS_PIN);

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, reset, watch, setValue } = methods;

  const accountFileName = useMemo(() => `${patientInfo?.user?.firstName}_${patientInfo?.user?.lastName}`, [patientInfo]);
  const accountTempVitalAccessURL = useMemo(() => getAccountTempAccessUrl(id, currentUser?.relyingParty.name), [id, currentUser]);
  const organizationLogo = useMemo(() => currentUser?.relyingParty?.logoUrl ?? '', [currentUser]);

  const isPatientTempVitalAccessEnabled = watch('temporaryVitalReadingAccess');

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = (values: FormValues) => {
    try {
      const updatedSettings = { ...jsonPatientSettings.current, ...values };

      updateAccountList({
        variables: {
          truentityId: id,
          settings: JSON.stringify(updatedSettings)
        }
      })
        .then(res => {
          if (res.data.updateAccountSetting) {
            setReloadPatientInfo(true);
            enqueueSnackbar('Patient settings updated successfully', { variant: 'success' });
          } else {
            enqueueSnackbar('Error occurred while updating patient settings', { variant: 'error' });
          }
        })
        .catch(() => {
          enqueueSnackbar('Request failed', { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
  };

  const handleConsentDialog = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    const title = isChecked ? 'Patient Data Sharing Consent Confirmation' : 'Disable Data Sharing Confirmation';
    const message = isChecked
      ? 'By enabling this function, you confirm that you have obtained explicit and informed consent from the patient to share their data, in accordance with applicable privacy laws and regulations.'
      : 'Are you sure you want to disable this setting? This action will prevent the sharing of patient data without explicit consent. Note that this action will also delete the associated PIN for this patient.';

    const modal = showModal(ConfirmDialog, {
      title,
      message,
      onAgree: () => {
        modal.hide();
        methods.setValue('temporaryVitalReadingAccess', isChecked, { shouldDirty: true });
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  const handleCopyButton = async () => {
    let timeout;
    try {
      await navigator.clipboard.writeText(accountTempVitalAccessURL);
      setIsCopied(true);

      timeout = setTimeout(() => setIsCopied(false), 5000);
    } catch (error) {
      console.error('Failed to copy text:', error);
      setIsCopied(false);
      clearTimeout(timeout);
    }
  };

  const handlePinModal = () => {
    const message = 'Set a 4-digit security PIN for this patient.';

    const modal = showModal(PinDialog, {
      title: 'Patient Security PIN',
      message: message,
      value: patientInfo?.isTempVitalAccessConfigured ? '****' : null,
      onSetPin(pinValue) {
        setPinForAccount({ variables: { truentityId: patientInfo?.truentityId, pinCode: pinValue } }).then(res => {
          if (res.data.updateAccountTempVitalAccessPin) {
            const { status, message } = res.data.updateAccountTempVitalAccessPin;
            const variant = status === 'Success' ? 'success' : 'error';
            enqueueSnackbar(message, { variant });
            setReloadPatientInfo(true);
            modal.hide();
          }
        });
      },
      onCancel: () => {
        modal.hide();
      }
    });
  };

  const handlePrintQRCodeModal = (link: string, accountName: string, organizationLogo: string) => {
    const modal = showModal(QRCodeDialog, {
      title: 'Print QR Code',
      id,
      link: link,
      downloadFileName: accountName,
      qrCodeImageUrl: organizationLogo,
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const handleUnblockVitalAccess = () => {
    setIsPatientTempVitalAccessBlocked(false);
    setValue('temporaryVitalReadingAccess', true, { shouldDirty: true });
  };

  useEffect(() => {
    try {
      if (patientInfo?.setting) {
        const extractedValue = extractValueFromJson(patientInfo?.setting ?? '{}', 'temporaryVitalReadingAccess');
        if (!extractedValue) {
          reset({ temporaryVitalReadingAccess: false });
        } else {
          reset({ temporaryVitalReadingAccess: extractedValue });
        }
        jsonPatientSettings.current = (JSON.parse(patientInfo?.setting) as JSONData) ?? {};
      }
      setIsPatientTempVitalAccessBlocked(patientInfo?.isTempVitalAccessBlocked || false);
    } catch (error) {
      console.error('Encountered an error while fetching patient settings');
    }
  }, [patientInfo, reset, jsonPatientSettings]);

  return (
    <Stack sx={{ background: theme.palette.background.default }}>
      <Box></Box>
      <FormProvider {...methods}>
        <Stack component="form">
          <Stack justifyContent="space-between" alignItems="center" direction="row" sx={{ px: 1 }}>
            {!isPatientTempVitalAccessBlocked ? (
              <>
                <Controller
                  control={control}
                  name="temporaryVitalReadingAccess"
                  defaultValue={false}
                  render={({ field: { value } }) => {
                    return (
                      <FormControlLabel
                        control={<Checkbox checked={value} onChange={handleConsentDialog} />}
                        label="Allow temporary access to recent vitals readings"
                        sx={{ flex: 1 }}
                      />
                    );
                  }}
                />

                <Box ref={setPinRef}>
                  {isPatientTempVitalAccessEnabled && (
                    <Stack direction="row" gap="20px">
                      <Tooltip title={accountTempVitalAccessURL}>
                        <Box>
                          <Button
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                            onClick={handleCopyButton}
                            color={isCopied ? 'success' : 'primary'}
                            size="small"
                            disabled={!patientInfo?.isTempVitalAccessConfigured}
                          >
                            {isCopied ? 'Copied' : 'Copy'} URL
                          </Button>
                        </Box>
                      </Tooltip>

                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<PatternIcon />}
                        onClick={handlePinModal}
                        color={patientInfo?.isTempVitalAccessConfigured ? 'primary' : 'error'}
                      >
                        {patientInfo?.isTempVitalAccessConfigured ? 'Update' : 'Set'} Pin
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<QrCode2Icon />}
                        onClick={() => handlePrintQRCodeModal(accountTempVitalAccessURL, accountFileName, organizationLogo)}
                        disabled={!patientInfo?.isTempVitalAccessConfigured}
                      >
                        QR Code
                      </Button>
                    </Stack>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Body2>
                  Access to this patient's temporary vitals has been blocked due to excessive login attempts. Click the button to unblock
                  access.
                </Body2>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<RemoveCircleIcon />}
                  color="warning"
                  onClick={handleUnblockVitalAccess}
                  disabled={!patientInfo?.isTempVitalAccessBlocked}
                >
                  Unblock Patient's vital access
                </Button>
              </>
            )}
          </Stack>
        </Stack>
        <AutoSave defaultValues={defaultValues} onSubmit={onSubmit} disableProgressAnimation />
      </FormProvider>
    </Stack>
  );
};

export default PatientSettings;
