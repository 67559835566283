import { AccountNameOrder, type FollowUpRemindersTypes, type User } from '@/types/accountProfile';
import { MedicalServices, Role, ROLE_TYPES } from '@/types/admin';
import type { CurrentPatient } from '@/types/chat';
import type { LoggedUser } from '@/types/graphql';
import type { MuiColor } from '@/types/mui';
import type { AccountsMonitoringDeviceType } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import type { SvgIconComponent } from '@mui/icons-material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import moment from 'moment';
import { patientTemporaryAccessUrlPaths } from './constants';
import { getServerAccessUrl } from './environment';
import { getCurrentDate, isBefore, isSameOrAfter, startOfDay } from './format';
import { emptyString, toKebabCase } from './string';

export function getAccountUserFullName(user: Partial<User> | undefined, order: AccountNameOrder = AccountNameOrder.FIRST_LAST): string {
  if (!user) {
    return emptyString();
  }

  if (order === AccountNameOrder.LAST_FIRST) return `${user.lastName || ''} ${user.firstName || ''}`;

  return `${user.firstName || ''} ${user.lastName || ''}`;
}

export const findClosestReminderForToday = (reminders: FollowUpRemindersTypes[]) => {
  const now = getCurrentDate();
  const today = startOfDay(now);

  let closestReminder: FollowUpRemindersTypes | null = null;

  if (!reminders || reminders?.length === 0) {
    return null;
  }

  for (const reminder of reminders) {
    const reminderTime = moment(reminder.followUpOn);
    if (isSameOrAfter(reminderTime, today)) {
      if (!closestReminder || isBefore(reminderTime, moment(closestReminder.followUpOn))) {
        closestReminder = reminder;
      }
    }
  }
  return closestReminder;
};

export const getAccountRedirectUrl = (
  truentityId: string,
  serviceName: string,
  patientStatus: string | null,
  accountsMonitoringDevices?: AccountsMonitoringDeviceType[],
  currentUser?: LoggedUser | null
) => {
  const currentUserMedicalServices = currentUser?.relyingParty?.medicalServices?.map(medicalService => medicalService.shortName) || [];
  const isMTMServiceAvailable = currentUserMedicalServices.includes(MedicalServices.MTM);
  const isRPMServiceAvailable = currentUserMedicalServices.includes(MedicalServices.RPM);
  const isRPMEnrolled = patientStatus === RpmStatusTypes.ENROLLED && accountsMonitoringDevices && accountsMonitoringDevices?.length > 0;

  if (
    (currentUser && isMTMServiceAvailable && serviceName === MedicalServices.MTM) ||
    (!currentUser && serviceName === MedicalServices.MTM)
  ) {
    return `../../patients/${truentityId}/details/medications/triage/list`;
  } else if (
    (currentUser && isRPMServiceAvailable && serviceName === MedicalServices.RPM) ||
    (!currentUser && serviceName === MedicalServices.RPM)
  ) {
    return isRPMEnrolled
      ? `../../patients/${truentityId}/details/rpm/readings`
      : `../../patients/${truentityId}/details/rpm/patient-setup/care-activity/clinical-summary`;
  } else {
    return `../../patients/${truentityId}/details/medications/triage/list`;
  }
};

export const getRoleTypeForCurrentUser = (currentUser: LoggedUser): string => {
  switch (currentUser.roleType) {
    case Role.PHARMACIST:
      return currentUser.title?.length > 0 ? getTitleLabel(currentUser.title) : 'Pharmacist';
    case Role.SUPER:
      return 'Administrator';
    case Role.PHARMACIST_ADMIN:
      return 'Supervisor';
    case Role.ADT_ADMIN:
      return 'Care Provider';
  }
  return 'Unknown';
};

export const getTitleLabel = (titleValue: string | undefined) => {
  const titleOption = ROLE_TYPES.find(option => option.value === titleValue);
  return titleOption ? titleOption.label : 'Unknown Title';
};

export const getAccountPrefix = (patient: CurrentPatient | null) => {
  if (patient?.gender?.toLowerCase() === 'male') return 'Mr.';
  else if (patient?.gender?.toLowerCase() === 'female') return 'Ms.';
  else return null;
};

export const getAccountTempAccessUrl = (truentityId: string | undefined, relyingPartyName: string | undefined) => {
  if (!truentityId || !relyingPartyName || typeof relyingPartyName !== 'string') return '#';

  const orgName = toKebabCase(relyingPartyName);

  const path = patientTemporaryAccessUrlPaths.replace('${company}', orgName);

  const url = new URL(getServerAccessUrl());
  url.pathname = path;
  url.searchParams.append('id', truentityId);
  return url.toString();
};

const roleIconMapping: Record<
  Role.PHARMACIST | Role.SUPER | Role.PHARMACIST_ADMIN,
  {
    Icon: SvgIconComponent;
    color: MuiColor;
  }
> = {
  [Role.SUPER]: {
    Icon: ManageAccountsIcon,
    color: 'error'
  },
  [Role.PHARMACIST]: {
    Icon: PersonIcon,
    color: 'primary'
  },
  [Role.PHARMACIST_ADMIN]: {
    Icon: SupervisorAccountIcon,
    color: 'secondary'
  }
};

export const getIconDataBasedOnRole = (roleType: Role) => {
  return roleIconMapping[roleType] || { Icon: null, color: null };
};

export const isCopilotEnabled = (currentUser: LoggedUser): boolean => {
  if (!currentUser) return false;

  try {
    const settingsString = currentUser.relyingParty?.settings;
    const settingsObject = settingsString ? JSON.parse(settingsString) : {};

    return settingsObject.AdditionalFeatures?.coPilot === 'true';
  } catch (error) {
    return false;
  }
};
